import { Builder } from "@builder.io/react";

/* LINK
 * https://www.builder.io/c/docs/custom-components-visual-editor
 * https://github.com/BuilderIO/builder/blob/main/packages/core/docs/interfaces/InsertMenuConfig.md
 * https://github.com/BuilderIO/builder/blob/main/examples/react-design-system/src/builder-settings.js
 */

Builder.register("insertMenu", {
  name: "Pricing Components",
  items: [
    { name: "pricing-card" },
    { name: "pricing-3cards-wtoggle" },
    { name: "pricing-calc-banner" },
    { name: "pricing-calc-link" },
    { name: "pricing-cta-button" },
    { name: "pricing-feature-list" },
    { name: "pricing-clinician-counter" },
    { name: "card-pricing-amount" },
    { name: "pricing-slider" },
    { name: "tooltip-icon" },
  ],
});

Builder.register("insertMenu", {
  name: "Testimonials Components",
  items: [
    { name: "testimonial-section-large" },
    { name: "testimonial-cards-arrows" },
  ],
});

Builder.register("insertMenu", {
  name: "CTAs Components",
  items: [
    { name: "cta-button" },
    { name: "cta-button-arrow" },
    { name: "cta-emailcapture" },
    { name: "email-capture-leads" },
    { name: "signup-form" },
  ],
});

Builder.register("insertMenu", {
  name: "FAQs Components",
  items: [{ name: "faqs-accordion" }, { name: "faqs-list-2column" }],
});

Builder.register("insertMenu", {
  name: "Content: Images Components",
  items: [
    { name: "content-3column-grid" },
    { name: "content-4column-grid" },
    { name: "content-accordian" },
    { name: "content-anchorlinks-box" },
    { name: "content-carousel-large" },
  ],
});

Builder.register("insertMenu", {
  name: "Content: Video Components",
  items: [{ name: "video-embed" }, { name: "cta-button-playvideo" }],
});

Builder.register("insertMenu", {
  name: "Banners Components",
  items: [
    { name: "banner-button-large" },
    { name: "banner-link-small" },
    { name: "banner-links-fullwidth" },
    { name: "global-announcement-banner" },
  ],
});

Builder.register("insertMenu", {
  name: "Molecules Components",
  items: [
    { name: "socialproof-hipaa-stars" },
    { name: "social-links" },
    { name: "system-status-dot" },
  ],
});

Builder.register("insertMenu", {
  name: "Rebrand Components",
  items: [
    { name: "section-margin" },
    { name: "ctaVideo" },
    { name: "cta" },
    { name: "videoEmbed" },
    { name: "faqs" },
  ],
});
